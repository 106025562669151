import {loaded} from '../document-ready'

function maxWidthOf(elements: Element[]): number {
  let maxWidth = 0

  for (const el of elements) {
    const rect = el.getBoundingClientRect()

    if (rect.width > maxWidth) {
      maxWidth = rect.width
    }
  }

  return maxWidth
}

// Sorts two strings based on the order we want labels to appear in the spider graph
function labelSort(a: string, b: string): number {
  if (a.length < b.length) {
    return -1
  }

  if (a.length > b.length) {
    return 1
  }

  return a.localeCompare(b)
}

// Return a value used for centering the percentage label above the type label
function percentElOffset(percentEl: Element, labelEl: Element): number {
  const percentRect = percentEl.getBoundingClientRect()
  const labelRect = labelEl.getBoundingClientRect()

  return (labelRect.width - percentRect.width) / 2
}

function displaySpiderGraph(container: Element, data: {percentages: {[key: string]: number}}) {
  const svg = container.querySelector('.js-activity-overview-graph')
  if (!svg) {
    return
  }

  const blob = svg.querySelector('.js-highlight-blob')!
  const padding = 10
  const minWidth = 250
  const availableWidth = Math.floor(container.clientWidth)
  const maxWidth = Math.max(availableWidth, minWidth)
  const maxHeight = maxWidth
  const topLabelEl = svg.querySelector('.js-highlight-label-top')!
  const bottomLabelEl = svg.querySelector('.js-highlight-label-bottom')!
  const rightLabelEl = svg.querySelector('.js-highlight-label-right')!
  const leftLabelEl = svg.querySelector('.js-highlight-label-left')!
  const topPercentEl = svg.querySelector('.js-highlight-percent-top')!
  const bottomPercentEl = svg.querySelector('.js-highlight-percent-bottom')!
  const rightPercentEl = svg.querySelector('.js-highlight-percent-right')!
  const leftPercentEl = svg.querySelector('.js-highlight-percent-left')!
  const xAxis = svg.querySelector('.js-highlight-x-axis')!
  const yAxis = svg.querySelector('.js-highlight-y-axis')!
  const topEllipse = svg.querySelector('.js-highlight-top-ellipse')!
  const rightEllipse = svg.querySelector('.js-highlight-right-ellipse')!
  const bottomEllipse = svg.querySelector('.js-highlight-bottom-ellipse')!
  const leftEllipse = svg.querySelector('.js-highlight-left-ellipse')!
  const spinner = container.querySelector('.js-activity-overview-graph-spinner')!
  const groupEl = svg.querySelector('g')!

  // Reset graph and display the right parts; necessary to reset some attributes in case another
  // part of the page loads via pjax and the user hits the Back button in their browser
  groupEl.removeAttribute('transform')
  /* eslint-disable-next-line github/no-d-none */
  svg.classList.remove('d-none')
  svg.classList.add('d-block')
  spinner.classList.remove('d-block')
  /* eslint-disable-next-line github/no-d-none */
  spinner.classList.add('d-none')
  topLabelEl.innerHTML = ''
  rightLabelEl.innerHTML = ''
  bottomLabelEl.innerHTML = ''
  leftLabelEl.innerHTML = ''
  topPercentEl.innerHTML = '&nbsp;'
  rightPercentEl.innerHTML = '&nbsp;'
  bottomPercentEl.innerHTML = '&nbsp;'
  leftPercentEl.innerHTML = '&nbsp;'

  // Apply labels going clockwise starting from the top
  const labels = Object.keys(data.percentages).sort(labelSort)
  const [leftLabel, rightLabel] = labels.slice(0, 2).sort()
  const [topLabel, bottomLabel] = labels.slice(2, 4).sort()

  const topValue = data.percentages[topLabel]
  const rightValue = data.percentages[rightLabel]
  const bottomValue = data.percentages[bottomLabel]
  const leftValue = data.percentages[leftLabel]

  let nonZeroPercentEl: Element | null = null
  const percentageLabel = (label: string, percentEl: Element) => {
    const percentage = data.percentages[label]
    if (percentage > 0) {
      nonZeroPercentEl = percentEl
      return `${percentage}%`
    }
  }

  // Set percentage labels
  const topPercentText = percentageLabel(topLabel, topPercentEl)
  if (topPercentText) {
    topPercentEl.textContent = topPercentText
  }
  const rightPercentText = percentageLabel(rightLabel, rightPercentEl)
  if (rightPercentText) {
    rightPercentEl.textContent = rightPercentText
  }
  const bottomPercentText = percentageLabel(bottomLabel, bottomPercentEl)
  if (bottomPercentText) {
    bottomPercentEl.textContent = bottomPercentText
  }
  const leftPercentText = percentageLabel(leftLabel, leftPercentEl)
  if (leftPercentText) {
    leftPercentEl.textContent = leftPercentText
  }

  // Position the axis labels
  const topLabelDX = maxWidth / 2
  const bottomLabelDX = maxWidth / 2
  topLabelEl.appendChild(document.createTextNode(topLabel))
  topLabelEl.setAttribute('dx', topLabelDX.toString())
  bottomLabelEl.appendChild(document.createTextNode(bottomLabel))
  bottomLabelEl.setAttribute('dx', bottomLabelDX.toString())
  topPercentEl.setAttribute('dx', topLabelDX.toString())
  bottomPercentEl.setAttribute('dx', bottomLabelDX.toString())

  const labelHeight = topLabelEl.getBoundingClientRect().height
  let percentHeight = 0
  if (nonZeroPercentEl) {
    percentHeight = (nonZeroPercentEl as Element).getBoundingClientRect().height
  }

  // Position remaining axis labels
  let leftLabelDY = maxHeight / 2 + labelHeight / 4
  let rightLabelDY = maxHeight / 2 + labelHeight / 4
  if (leftValue > 0) {
    leftLabelDY = leftLabelDY + percentHeight / 2 + padding / 4
  }
  if (rightValue > 0) {
    rightLabelDY = rightLabelDY + percentHeight / 2 + padding / 4
  }
  leftLabelEl.appendChild(document.createTextNode(leftLabel))
  leftLabelEl.setAttribute('dy', leftLabelDY.toString())
  rightLabelEl.appendChild(document.createTextNode(rightLabel))
  rightLabelEl.setAttribute('dy', rightLabelDY.toString())

  // Position left and right percentage labels vertically
  const leftPercentDY = leftLabelDY - labelHeight
  const rightPercentDY = rightLabelDY - labelHeight
  leftPercentEl.setAttribute('dy', leftPercentDY.toString())
  rightPercentEl.setAttribute('dy', rightPercentDY.toString())

  // Get a value from 0-1 to represent how much each axis represents of the total
  let topPercentage = topValue / 100
  let bottomPercentage = bottomValue / 100
  let leftPercentage = leftValue / 100
  let rightPercentage = rightValue / 100

  const minTarget = 0
  const maxTarget = 1
  const maxActual = Math.max(topPercentage, bottomPercentage, leftPercentage, rightPercentage)
  const scaleAxisPercentage = (x: number) => {
    const numerator = (maxTarget - minTarget) * x
    const denominator = maxActual
    return numerator / denominator + minTarget
  }

  // Scale each percentage up so the largest contribution type takes up 100% of its axis;
  // otherwise we end up with an accurately-sized-but-dinky graph
  topPercentage = scaleAxisPercentage(topPercentage)
  bottomPercentage = scaleAxisPercentage(bottomPercentage)
  leftPercentage = scaleAxisPercentage(leftPercentage)
  rightPercentage = scaleAxisPercentage(rightPercentage)

  // Determine how much room we have in the middle of the svg for the blob, accounting for
  // labels and padding
  const widestLabelWidth = maxWidthOf([leftLabelEl, rightLabelEl, topLabelEl, bottomLabelEl]) + padding
  const strokeWidth = 4 // how much room to leave for the border around the blob
  const labelSize = Math.max(widestLabelWidth, labelHeight)
  const widthMidpoint = maxWidth / 2
  const heightMidpoint = maxHeight / 2
  const topX = widthMidpoint
  const topY100 = labelSize
  const bottomX = widthMidpoint
  const bottomY100 = maxHeight - labelSize
  const leftX100 = labelSize
  const leftY = heightMidpoint
  const rightX100 = maxWidth - labelSize
  const rightY = heightMidpoint

  // Determine how big each axis can be, accounting for how much room the labels take up
  const maxSegmentWidth = widthMidpoint - leftX100
  const maxSegmentHeight = heightMidpoint - topY100

  // Determine the right position for each axis to indicate how big that contribution type was
  let topY = topY100 + (1 - topPercentage) * maxSegmentHeight
  let bottomY = Math.max(bottomY100 - (1 - bottomPercentage) * maxSegmentHeight, heightMidpoint)
  let leftX = leftX100 + (1 - leftPercentage) * maxSegmentWidth
  let rightX = rightX100 - (1 - rightPercentage) * maxSegmentWidth

  // Position the axes going horizontally across the graph and vertically down the middle
  xAxis.setAttribute('x1', leftX100.toString())
  xAxis.setAttribute('y1', heightMidpoint.toString())
  xAxis.setAttribute('x2', rightX100.toString())
  xAxis.setAttribute('y2', heightMidpoint.toString())
  yAxis.setAttribute('x1', widthMidpoint.toString())
  yAxis.setAttribute('y1', topY100.toString())
  yAxis.setAttribute('x2', widthMidpoint.toString())
  yAxis.setAttribute('y2', bottomY100.toString())

  // Adjust the bounds of the blob to allow for the stroke that sticks out from the edges of the
  // blob, but only if doing so won't pull that part of the blob to the other side of the
  // relevant axis
  if (topY + strokeWidth < heightMidpoint) {
    topY = topY + strokeWidth
  }
  if (bottomY - strokeWidth > heightMidpoint) {
    bottomY = bottomY - strokeWidth
  }
  if (leftX + strokeWidth < widthMidpoint) {
    leftX = leftX + strokeWidth
  }
  if (rightX - strokeWidth > widthMidpoint) {
    rightX = rightX - strokeWidth
  }

  // Position the labels at the ends of the axes
  const topLabelDY = topY100 - padding
  const rightLabelDX = rightX100 + padding
  let bottomLabelDY = bottomY100 + padding + padding * 0.8
  if (bottomValue > 0) {
    bottomLabelDY = bottomLabelDY + percentHeight
  }
  const leftLabelDX = leftX100 - (padding + padding * 0.2)
  topLabelEl.setAttribute('dy', topLabelDY.toString())
  rightLabelEl.setAttribute('dx', rightLabelDX.toString())
  bottomLabelEl.setAttribute('dy', bottomLabelDY.toString())
  leftLabelEl.setAttribute('dx', leftLabelDX.toString())

  // Position the x-axis percentages centered above their labels
  const leftPercentDX = leftLabelDX - percentElOffset(leftPercentEl, leftLabelEl)
  const rightPercentDX = rightLabelDX + percentElOffset(rightPercentEl, rightLabelEl)
  leftPercentEl.setAttribute('dx', leftPercentDX.toString())
  rightPercentEl.setAttribute('dx', rightPercentDX.toString())

  const topPercentDY = topLabelDY - labelHeight
  const bottomPercentDY = bottomLabelDY - labelHeight
  topPercentEl.setAttribute('dy', topPercentDY.toString())
  bottomPercentEl.setAttribute('dy', bottomPercentDY.toString())

  // Position point dots at the edges of the blob for each contribution type
  const dotOffset = 2
  if (topValue === 0) {
    /* eslint-disable-next-line github/no-d-none */
    topEllipse.classList.add('d-none')
  } else {
    topEllipse.setAttribute('cx', topX.toString())
    topEllipse.setAttribute('cy', (topY - dotOffset).toString())
  }
  if (rightValue === 0) {
    /* eslint-disable-next-line github/no-d-none */
    rightEllipse.classList.add('d-none')
  } else {
    rightEllipse.setAttribute('cx', (rightX + dotOffset).toString())
    rightEllipse.setAttribute('cy', rightY.toString())
  }
  if (bottomValue === 0) {
    /* eslint-disable-next-line github/no-d-none */
    bottomEllipse.classList.add('d-none')
  } else {
    bottomEllipse.setAttribute('cx', bottomX.toString())
    bottomEllipse.setAttribute('cy', (bottomY + dotOffset).toString())
  }
  if (leftValue === 0) {
    /* eslint-disable-next-line github/no-d-none */
    leftEllipse.classList.add('d-none')
  } else {
    leftEllipse.setAttribute('cx', (leftX - dotOffset).toString())
    leftEllipse.setAttribute('cy', leftY.toString())
  }

  // Position the graph at the top left of the containing svg
  const groupBounds = groupEl.getBoundingClientRect()
  const svgBounds = svg.getBoundingClientRect()
  const topOffset = 0 - (groupBounds.top - svgBounds.top)
  const leftOffset = 0 - (groupBounds.left - svgBounds.left)
  groupEl.setAttribute('transform', `translate(${leftOffset}, ${topOffset})`)

  // Resize the containing svg to match the actual size of the graph
  const svgWidth = Math.ceil(groupBounds.width)
  const svgHeight = Math.ceil(groupBounds.height)
  svg.setAttribute('width', svgWidth.toString())
  svg.setAttribute('height', svgHeight.toString())

  // Populate the blob with points to represent how many contributions of each type were made
  const pointPairs = [
    [rightX, rightY],
    [bottomX, bottomY],
    [leftX, leftY]
  ]
  const pointPairsStr = pointPairs.map(pair => `L${pair[0]},${pair[1]}`).join(' ')
  const pathData = `M${topX},${topY} ${pointPairsStr} z`

  if (topValue + rightValue + bottomValue + leftValue !== 0) {
    blob.setAttribute('d', pathData)
  }
}

export async function initializeOverviewGraphContainer(container: Element) {
  await loaded
  const percentages = container.getAttribute('data-percentages')!
  const data = {percentages: JSON.parse(percentages)}
  displaySpiderGraph(container, data)
}
